import React from "react";
import Upcomingprojects from "./UpcomingProjects/Upcomingprojects";
import Carousel from "./Carousel/Carousel";
import Featured from "./Featuredprojects/Featured";
import Exclusive from "./Exclusive/Exclusive";
import Investinplots from "./Invest/Investinplots";
import WhyWic from "./WhyWic/WhyWic";
import ValidationForm from "./ValiadtionForm/ValidationForm";
import Ourleadership from "./Ourleadership/Ourleadership";
import Slider from "./Events/Events";
const HomePage = () => {
  return (
    <div className="backgroundimg">
      {/* <ValidationForm /> */}
      <Carousel />
      <Upcomingprojects />
      <Exclusive />
      <Featured type="Featured" />
      <Investinplots />
      <WhyWic />
      <Slider />
      <Ourleadership />
    </div>
  );
};
export default HomePage;