import React, { useState } from 'react';
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
const Footer = () => {
  const [openFooter, setOpenFooter] = useState(null);
  const toggleFooter = (index) => {
    setOpenFooter(prevIndex => (prevIndex === index ? null : index));
  };
  return (
    <div className='footer-main-div'>
        <div className='container footer-desktop-container'>
        <img className='womeki-logo' src="/womeki-logo.webp" alt='logo'/>
            <h6 className='footerhead'>Womeki Investors Club</h6>
            <p className='footercontent'>(WIC) is a premier real estate company that has been operating in India since 2021. We focus on selling and purchasing residential projects, hotels, farmhouses, plots across the country, and prime commercial properties, such as office and retail spaces, in the Delhi NCR and other major cities.</p>
           <div className='row mt-5'>
                <div className='col-md-3 col-sm-12'>
                    <h5 className='footer-list-head'>PROJECTS</h5>
                    <ul className='footer-list-item '>
                      <a href='/product/66a8af0586f2ae664f21c10f'>
                   <li className='mb-2'>ETHEREA</li></a>
                 {/* <a href='/product/660b93f01a7b9c0d54a6e49f'>
               <li className='mb-2'>WOMEKI WOODS</li></a> */}
               <a href='/product/66a895f986f2ae664f21ad9a'>
               <li className='mb-2'>NEXURIA</li></a>
               </ul>
                </div>
               <div className='col-md-3 col-sm-12 '>
                    <h5 className='footer-list-head'>ADDRESS</h5>
                    <ul  className='footer-list-item'>
                   <li className='mb-2'>WIC, 22nd floor, Tower-C, Bhutani Alphathum Sector-90, Noida, Pincode-2010305 <FontAwesomeIcon className="location-icon ms-1 mb-1" icon={faLocationDot} /></li>
               </ul>
                </div>
                <div className='col-md-3 col-sm-12'>
                    <h5 className='footer-list-head'>CONTACTS</h5>
                    <ul  className='footer-list-item'>
                    <a className='foot-mail-link' href = "mailto:womekiinvestorsclub@gmail.com"><li className='mb-2'>info@wic.org.in</li></a>
                 	<a className='foot-mail-link' href="tel:+91 9911140024"><li className='mb-2'>+91 - 8506900024</li></a>
               </ul>
               </div>
               <div className='col-md-3 col-sm-12 '>
                    <h5 className='footer-list-head'>SOCIAL MEDIA</h5>
                    <div className='social-div'>
                    <a href='https://instagram.com/womekiinvestorsclub?igshid=OGQ5ZDc2ODk2ZA==' target="_blank" rel="noopener noreferrer">
                      <div className='social-icon-div me-4 mt-2'>
                  <FontAwesomeIcon className='social-icon' icon={faInstagram} /></div></a>
                <a href='https://www.facebook.com/womekiinvestorsclub' target="_blank" rel="noopener noreferrer">
                  <div className='social-icon-div me-4 mt-2'>
                   <FontAwesomeIcon className='social-icon' icon={faFacebookF} />
                   </div></a>
              <a href='https://www.linkedin.com/company/womeki-investors-club-wic/' target="_blank" rel="noopener noreferrer">
                <div className='social-icon-div mt-2'>
                  <FontAwesomeIcon className='social-icon' icon={faLinkedinIn} />
                  </div></a>
               </div>
                </div>
                <p className='footercontent text-center pb-3 pt-3'>All Right Reserved By Womeki Tech Solutions</p>
               </div>
           </div>
           <div className='container footer-mobile-container'>
           <img className='womeki-logo' src="/womeki-logo.webp" alt='logo'/>
            <h6 className='footerhead'>Womeki Investors Club</h6>
            <p className='footercontent'>(WIC) is a premier real estate company that has been operating in India since 2021. We focus on selling and purchasing residential projects, hotels, farmhouses, plots across the country, and prime commercial properties, such as office and retail spaces, in the Delhi NCR and other major cities.</p>
           <div className='row mt-5'>
                <div className='col-md-2 col-sm-12'>
                  <div className='d-flex justify-content-between'>
                    <h5 className='footer-list-head'>PROJECTS</h5>
                    <FontAwesomeIcon className='caret-icon' icon={faCaretDown}  onClick={() => toggleFooter(1)}/>
                    </div>
                    {openFooter === 1 && (
                    <ul className='footer-list-item '>
                      <a href='/product/66a8af0586f2ae664f21c10f'>
                   <li className='mb-2'>ETHEREA</li></a>
                   {/* <a href='/product/660b93f01a7b9c0d54a6e49f'>
               <li className='mb-2'>WOMEKI WOODS</li></a> */}
               <a href='/product/66a895f986f2ae664f21ad9a'>
               <li className='mb-2'>NEXURIA</li></a>
               </ul>
                    )}
                </div>               
                <div className='col-md-3 col-sm-12'>
                <div className='d-flex justify-content-between'>
                    <h5 className='footer-list-head'  onClick={() => toggleFooter(3)}>ADDRESS</h5>
                    <FontAwesomeIcon className='caret-icon' icon={faCaretDown}  onClick={() => toggleFooter(3)}/>
                    </div>
                    {openFooter === 3 && (
                    <ul  className='footer-list-item'>
                    <li className='mb-2'>WIC, 22nd floor, Tower-C, Bhutani Alphathum Sector-90, Noida, Pincode-2010305 <FontAwesomeIcon className="location-icon ms-1 " icon={faLocationDot} /></li>
               </ul>
                    )}
                </div>
                <div className='col-md-3 col-sm-12'>
                <div className='d-flex justify-content-between'>
                    <h5 className='footer-list-head' onClick={() => toggleFooter(4)}>CONTACTS</h5>
                    <FontAwesomeIcon className='caret-icon' icon={faCaretDown}  onClick={() => toggleFooter(4)}/>
                    </div>
                    {openFooter === 4 && (
                    <ul  className='footer-list-item'>
                    <a className='foot-mail-link' href = "mailto:womekiinvestorsclub@gmail.com"><li className='mb-2'>info@wic.org.in</li></a>
                 	<a className='foot-mail-link' href="tel:+91 9911140024"><li className='mb-2'>+91 - 8506900024</li></a>
               </ul>
                    )}
               </div>
               <div className='col-md-2 col-sm-12'>
               <div className='d-flex justify-content-between'>
                    <h5 className='footer-list-head' onClick={() => toggleFooter(5)}>SOCIAL MEDIA</h5>
                    <FontAwesomeIcon className='caret-icon' icon={faCaretDown}  onClick={() => toggleFooter(5)}/>
                    </div>
                    {openFooter === 5 && (
                    <div className='social-div'>
                    <a href='https://instagram.com/womekiinvestorsclub?igshid=OGQ5ZDc2ODk2ZA==' target="_blank" rel="noopener noreferrer">
                    <div className='social-icon-div mt-2 me-4'>
                  <FontAwesomeIcon className='social-icon ' icon={faInstagram} /></div></a>
                <a href='https://www.facebook.com/womekiinvestorsclub' target="_blank" rel="noopener noreferrer">
                <div className='social-icon-div mt-2 me-4'>
                   <FontAwesomeIcon className='social-icon' icon={faFacebookF} /></div></a>
              <a href='https://www.linkedin.com/company/womeki-investors-club-wic/' target="_blank" rel="noopener noreferrer"> 
              <div className='social-icon-div mt-2 '>
               <FontAwesomeIcon className='social-icon ' icon={faLinkedinIn} /></div></a>
               </div>
                    )}
                </div>
               </div>
           </div>
           </div>      
  )
}
export default Footer